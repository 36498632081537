import(/* webpackMode: "eager" */ "/Users/macbookpro/Documents/saltovn/itonami/src/matchin-client/node_modules/next/dist/client/components/app-router.js");
;
import(/* webpackMode: "eager" */ "/Users/macbookpro/Documents/saltovn/itonami/src/matchin-client/node_modules/next/dist/client/components/client-page.js");
;
import(/* webpackMode: "eager" */ "/Users/macbookpro/Documents/saltovn/itonami/src/matchin-client/node_modules/next/dist/client/components/error-boundary.js");
;
import(/* webpackMode: "eager" */ "/Users/macbookpro/Documents/saltovn/itonami/src/matchin-client/node_modules/next/dist/client/components/layout-router.js");
;
import(/* webpackMode: "eager" */ "/Users/macbookpro/Documents/saltovn/itonami/src/matchin-client/node_modules/next/dist/client/components/not-found-boundary.js");
;
import(/* webpackMode: "eager" */ "/Users/macbookpro/Documents/saltovn/itonami/src/matchin-client/node_modules/next/dist/client/components/render-from-template-context.js");
